$border-color: #ececec;
$color-white: #fff;
$color-red: #c11e04;
$color-green-light: #e6f1ec;
// $color-green: #016c42;
$color-green: #01B57A;
$color-purple: #7E3895;
$color-green-dark: #004b2e;
$color-gray: #d3d3d3;
$color-gray-light: #F8F8F8;
$color-gray-dark: #a8a8a8;
$color-input-border: #d3d3d3;

// $color-blue-dark: #081D3A;
$color-blue-dark: #081D3A;

$card-color: $color-white;

$background-color: $color-gray-light;

$date-btn-color: $color-green;

$standard-font-size: .875rem;
$medium-font-size: 1.25rem;

@font-face {
  font-family: "Keep Calm Medium";
  src: url('../public/assets/fonts/keepcalm-medium-webfont.woff2') format('woff2');
}
@font-face {
  font-family: "Keep Calm Heavy";
  src: url('../public/assets//fonts/keepcalm-heavy-webfont.woff2') format('woff2');
}
@font-face {
  font-family: "Keep Calm Book";
  src: url('../public/assets/fonts/keepcalm-book-webfont.woff2') format('woff2');
}

// @import './header';
@import './_slides';

html,
body {
  background-color: $background-color;
}

.st-card {
  background-color: $card-color;
  padding: 32px 16px;
  margin-top: 24px;

  // padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid $border-color;
  color: #222;

  &:not(.st-checkbox) {
    // min-height: 600px;
  }

  @media screen and (min-width: 650px) {
    padding: 32px 145px;
  }
}


.st-cont {
  * {
    font-family: "Keep Calm Book", sans-serif;
  }
  position: relative;
  padding-top: 8px;
  padding-bottom: 75px;
  max-width: 770px;
  margin-right: auto;
  margin-left: auto;

  .loader {
    margin-left: auto;
    margin-right: auto;
    width: 84px;
    height: 64px;
  }

  .title {
    font-size: 1.75rem;
  }

  .fnt-medium {
    font-family: "Keep Calm Medium", sans-serif;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
  }

  h2 {
    font-size: $medium-font-size;
    line-height: 1.625rem;
    font-weight: 500;
    margin: 16px 40px 0 0;
  }

  .st-step {
    display: block;
    margin-top: 16px;

    &.active {
      display: block;
    }
  }

  span, label, .st-label {
    font-size: $standard-font-size;
  }
  .text-md {
    font-size: 16px;
  }
  .text-lg {
    font-size: $medium-font-size;
  }
  .pb-sm {
    padding-bottom: 8px;
  }
  .st-label {
    display: block;
    color: #222;
    font-style: normal;
    line-height: 1.125rem;
    font-weight: 400;
    width: 100%;
    text-align: left;
    margin-top: 24px;

    &.txt-sm {
      font-size: .7rem;
    }
  }

  .st-label+input.form-control,
  .st-label+select.form-control,
  .st-label+.row,
  .st-label+textarea.form-control {
    margin-top: 8px;
  }

  a {
    font-size: $standard-font-size;
  }

  a, .subtitle {
    color: $color-green;
  }

  input,
  select {
    &.form-control {
      -webkit-appearance: none;
      border-radius: 4px;
      display: block;
      position: relative;
      background-color: #fff;
      border: 2px solid $color-input-border;
      box-sizing: border-box;
      padding: 12px 16px;
      color: #222;
      width: 100%;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: inherit;

      &.is-error {
        border-color: $color-red;
      }

      &:focus {
        box-shadow: 0 0 0 0;
      }
    }
  }

  .pd-sm {
    padding: 8px;
  }

  .fc-event {
    text-align: center;
    cursor: pointer;
  }

  .status-lbl {
    margin-top: 8px;
  }

  .error-lbl {
    color: $color-red;
  }

  .success-lbl {
    color: $color-green-dark;
  }

  .btn-success,
  .btn-next,
  .btn-prev {
    width: auto;
    margin: 0 32px 0 0;
    height: 56px;
    padding: 16px 40px;
    background-color: $color-green;
    border-radius: 360px;
    // margin-left: auto;
    // margin-right: auto;
    display: flex;
    margin-top: 16px;

    &:hover {
      background-color: $color-green-dark;
    }
  }

  .btn-prev {
    display: none;
    border-color: $color-gray;
    background-color: $color-gray;
    color: $color-green;

    &:hover {
      background-color: $color-gray-dark;
    }
  }

  .btn-cont {
    display: none;
    justify-content: center;
    &.show {
      display: flex;
    }

    a {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .slides {
    .btn-cont {
      display: flex;
    }
  }

  .st-dob {
    text-align: center;
  }


  $date-btn-size: 66px;
  .date-btn {
    background-color: $color-white;
    color: $date-btn-color;
    width: $date-btn-size;
    height: $date-btn-size;
    border-radius: $date-btn-size/2;
    text-align: center;
    padding-top: 7px;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;

    &.active {
      background-color: $date-btn-color;
      color: $color-white;
    }

    & > div {
      font-size: 14px;
    }
  }
  // .date-btn > div {
  //   line-height: 16px;
  // }
  .date-nav {
    cursor: pointer;

    a {
      padding: 8px;
    }
  }
  .calendar-date {
    margin: 32px 72px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .calendar-times > div {
    display: flex;
    justify-content: center;
  }
  .calendar-time {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    width: 86px;
    height: 48px;
    border-radius: 100px;
    background-color: #e6f1ec;
    margin-bottom: 8px;

    &.active {
      background-color: $color-green-dark;
      color: #fff;
    }
    &.disabled {
      cursor: not-allowed;
      background-color: $color-gray;
      border-color: $color-gray-dark;
    }
  }

  .scroll-cont {
    max-height: 200px;
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 25px;
    font-size: $standard-font-size;
    // background: rgba(0,0,0,.05);
  }

  .dashed-cont, .scroll-cont {
    border: 2px dashed $color-input-border;
    border-radius: 5px;

    &.active {
      border: 3px dashed $color-green;
    }
  }

  .dashed-cont {
    padding: 8px;
    margin-bottom: 8px;
    // cursor: pointer;
  }

  .branch-cont {
    padding-top: 16px;
    padding-bottom: 16px;

    .dashed-cont {
      cursor: pointer;
    }
  }

  .banner {
    color: $color-green; 
    // font-size: 1.1em; 
    font-weight: bold; 
    margin: 0 0 20px; 
    padding: 5px 10px; 
    line-height: 1.4; 
    background-color: $border-color;
  }

  .divider {
    height: 1px;
    width: 100%;
    border: 1px solid $border-color;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .slide-nav-btn {
    padding: 8px;
  }
  
  .checkbin,
  .checknumb,
  .checkset {
    display: none
  }

  .checkset+label {
    display: inline-block;
    margin: .25em 0;
    margin-right: .5em;
    padding: .75em 0;
    border: 1px solid #c1c1c1;
    border-radius: 2em;
    width: 9em;
    text-align: center
  }

  .checkbin:hover+label:before,
  .checknumb:hover+label,
  .checkset:hover+label {
    background-color: #f3f3f3;
    cursor: pointer
  }

  .checkbin+label,
  .checkbin+label:before,
  .checknumb+label,
  .checkset+label,
  input:not([type=radio]) {
    -webkit-transition: border-width .25s, border-color .25s;
    transition: border-width .25s, border-color .25s
  }

  .checkbin+label:focus,
  .checkbin+label:focus:before,
  .checknumb+label:focus,
  .checkset+label:focus,
  input:not([type=radio]):focus {
    border-width: 2px;
    border-color: #949494;
    outline: 0
  }

  .checknumb:checked+label,
  .checkset:checked+label {
    border: 0;
    color: #fff;
    background-color: #666
  }

  .checkbin:checked+label[date-check="1"]:before {
    border-color: #666;
    background-color: #666
  }

  .checknumb:checked+label:after,
  .checkset:checked+label:after {
    content: "";
    display: inline-block;
    margin-left: .5em;
    width: 1.8em;
    height: 1.8em;
    vertical-align: middle
  }

  .checknumb:checked+label:after,
  .checkset:checked+label:after {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgZD0iTTExLjU3OSAyMS4wMDRsNS4yMTMgNS4yMTIgMTIuNTMyLTEyLjUzMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) -10px
  }

  .checkbin:checked+label:before {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgZD0iTTExLjU3OSAyMS4wMDRsNS4yMTMgNS4yMTIgMTIuNTMyLTEyLjUzMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) -9px
  }

  .checknumb:checked+label:after,
  .checkset:checked+label:after {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAiUlEQVR42u3SMQqDQBSE4ZwgqSR4lRU8QsqAR7EItp5PlFwjfQLj4DbCK4KNDDofTP+zby9mZmZ2LgCuXFKNK7gBWa0cN3I31bg3VzrOcYeLA5C4D9duiJu4+16vUnE/ZC+NuBjw5L7IOsE/FyM14mJkszp3/+esCpExTioyxIlFPpY4MzMzO6QZWwhFJM4BuMoAAAAASUVORK5CYII=) -10px
  }

  .checkbin:checked+label:before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAiUlEQVR42u3SMQqDQBSE4ZwgqSR4lRU8QsqAR7EItp5PlFwjfQLj4DbCK4KNDDofTP+zby9mZmZ2LgCuXFKNK7gBWa0cN3I31bg3VzrOcYeLA5C4D9duiJu4+16vUnE/ZC+NuBjw5L7IOsE/FyM14mJkszp3/+esCpExTioyxIlFPpY4MzMzO6QZWwhFJM4BuMoAAAAASUVORK5CYII=) -9px
  }

  .checktitle+label {
    margin-right: 1em;
    width: 6em
  }

  .checknumb+label {
    display: inline-block;
    margin: .25em 0;
    padding: .75em 1.5em;
    border: 1px solid #c1c1c1;
    border-left: 0;
    text-align: center
  }

  .checknumbf+label {
    border-left: 1px solid #c1c1c1;
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em
  }

  .checknumbl+label {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em
  }

  .checkbin+label {
    margin-right: 1.5em
  }

  .checkbin+label:before {
    content: "";
    position: relative;
    top: .5em;
    display: inline-block;
    margin-right: .5em;
    border: 1px solid #c1c1c1;
    border-radius: .25em;
    width: 1.8em;
    height: 1.8em;
    background-color: #fff
  }

  .outer-link {
    color: $color-green;
  }
}

#form {
  position: relative;
  .outer-link {
    display: none;
  }
  .settings-cont {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    z-index: 9999999;

    a {
      color: $color-green;
    }
  }
}

.st-radio,
.st-checkbox {
  cursor: pointer;
  padding: 7px 7px 23px 23px;

  &.is-selected {
    background-color: $color-green-light;
    border: 2px solid $color-green-dark;
  }
}

.theme-green {
  // background-color: $color-green;
  // height: 100%;

  &, body {
    background-color: $color-green;
    height: 100%;
  }

  .settings-cont {
    a {
      color: $color-blue-dark !important;
    }
  }

  .st-cont {
    .btn-success,
    .btn-next,
    .btn-prev {
      background-color: $color-blue-dark;
  
      &:hover {
        background-color: $color-blue-dark;
      }
    }
  
    .btn-prev {
      border-color: $color-gray;
      background-color: $color-gray;
      color: $color-green;
    }

    .outer-link, .btn-prev {
      color: $color-blue-dark;

      &:hover {
        background-color: $color-gray-dark;
      }
    }

    & > span, 
    & > h1 {
      color: $color-white;
    }
  }
}